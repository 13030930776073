<template>
    <div
        :class="[
            { '-active': isActive },
            { '-current': isCurrent },
            { '-promo': hasPromo },
            { '-disabled': isDisabled || isDowngradingOnTrial },
            'plan',
        ]">
        <div
            @click="handleCardClick"
            @mouseover="setPlanTypeFromMouseOver"
            :class="[
                this.$parent.$options.name === 'Cadence'
                    ? 'plan-box -multi-sub cadence-sub'
                    : 'plan-box -multi-sub',
                'plan-box-compliance',
            ]">
            <!-- main plan box -->
            <div
                ref="planWrapper"
                class="plan-box__wrapper"
                :style="{ 'min-height': isMobile ? 'initial' : maxPlanInfoHeight + 'px' }">
                <div
                    v-if="getPillText"
                    :class="[
                        'plan-box__pill',
                        'promotion-available',
                        { '-current': isCurrent },
                        { '-promo': hasPromo },
                    ]"
                    data-ci="promotion-available">
                    {{ getPillText }}
                </div>

                <div
                    v-if="!getPillText && getAnnualSavingsText && !hasPromoForMonthlyCadenceOnly"
                    :class="getAnnualPlanHighlightClasses"
                    data-ci="plan-box__annual-savings-pill">
                    <AnnualCouponIcon v-if="isAnnualPlanHighlightShowDiscountIcon" />
                    <span>{{ getAnnualSavingsText }}</span>
                </div>

                <div class="plan-box__title" ref="title">
                    <h3>{{ plan.plan_title }}</h3>
                    <span class="plan-box__radio">
                        <span class="plan-box__outline"></span>
                    </span>
                </div>

                <div
                    :class="[
                        'plan-box__copy',
                        { 'plan-box__copy--plan-description-empty': isPlanDescriptionEmpty },
                        { 'copy-equivalent': isAnnualPlanHighlightAll },
                    ]"
                    ref="copy">
                    {{ plan.plan_description }}
                </div>
                <div
                    v-show="showDiscountedSubtotals"
                    class="original-price-line-through"
                    data-ci="original-price-line-through">
                    {{ `${plan.plan_price} ${plan.plan_currency}` }}
                </div>
                <div
                    :class="[
                        'plan-box__price',
                        'plan-price',
                        !getPromoHeadline ? '-no-headline' : '',
                        alignCadencePrices ? '-align-cadences' : '',
                    ]"
                    ref="price">
                    <div class="plan-price__price" :class="{ '-four-plans': totalPlans >= 4 }">
                        <div
                            v-show="showDiscountedSubtotals"
                            class="plan-price__price--discounted-subtotals">
                            <span
                                v-show="showNext"
                                :id="'subtotal_next_' + this.idx"
                                class="plan-price__price">
                                {{ formattedSubtotalNext }}
                            </span>
                            <span
                                v-show="!showNext"
                                :id="'subtotal_now_' + this.idx"
                                class="plan-price__price">
                                {{ formattedSubtotalNow }}
                            </span>
                        </div>
                        <span v-show="!showDiscountedSubtotals">
                            {{ plan.plan_price }}
                        </span>
                    </div>
                    <div class="plan-price__wrapper" :class="{ '-four-plans': totalPlans >= 4 }">
                        <span class="plan-price__currency">
                            {{ plan.plan_currency }}
                        </span>
                        <span class="plan-price__period">
                            {{ plan.plan_period }}
                        </span>
                    </div>
                    <div
                        class="plan-price__promo-headline"
                        v-if="!isCurrent"
                        data-ci="promo-headline">
                        {{ getPromoHeadline }}
                    </div>
                    <div class="plan-price__trial" v-else-if="plan.plan_trial">
                        {{ plan.plan_trial }}
                    </div>
                    <div class="plan-price__renewal" data-ci="plan-price__renewal">
                        {{ planRenewalText() }}
                    </div>
                    <!-- If one cadence has additional text and the other doesn't, add a placeholder so that the heights are equal-->
                    <div
                        :class="
                            this.$parent.$options.name === 'Cadence' && !plan.plan_trial
                                ? 'plan-price__trial cadence-trial-price__margin'
                                : 'cadence-trial-price__placeholder'
                        "></div>
                </div>
            </div>

            <div v-if="showDividerLine" class="plan-box__divider"></div>

            <MultiSubBoxData
                v-if="showAdditionalInfo"
                v-bind="$props"
                :recurlyCoupon="coupon"
                :recurlyPricing="pricing"
                :showPromoDetails="showPromoDetails"></MultiSubBoxData>

            <ButtonCta :buttonConfig="getButtonConfig"></ButtonCta>
        </div>
    </div>
</template>

<script>
    import MultiSubBoxData from 'aa/vue/components/multisubplanpicker/MultiSubBoxData';
    import LearnMoreLegal from 'aa/vue/components/multisubplanpicker/LearnMoreLegal';
    import ButtonCta from 'atoms/ButtonCta';
    import couponsMixin from 'aa/vue/plugins/couponsMixin';

    import { mapGetters, mapActions } from 'vuex';
    import { CreditCardPaymentConfig } from 'aa/vue/FormConfig';
    import { tracking } from 'services/Tracking';
    import pricePrettyPrint from 'aa/vue/plugins/priceMixin';
    import {
        AB_TEST_BEST_PLAN_RECOMMENDED,
        AB_TEST_BEST_PLAN_RECOMMENDED_WITH_SAVE_STRING,
        AB_TEST_ANNUAL_PLAN_HIGHLIGHT,
        AB_TEST_ANNUAL_PLAN_HIGHLIGHT_ALL,
        AB_TEST_ANNUAL_PLAN_HIGHLIGHT_ICON_AND_GREEN_PILL,
        AB_TEST_ANNUAL_PLAN_HIGHLIGHT_GREEN_PILL,
    } from 'aa/helpers/featureConstants';
    import NotificationBarModel from 'aa/vue/models/NotificationBarModel';
    import Product from 'aa/vue/models/Product';
    import commonMixins from 'aa/vue/plugins/account/commonMixins';
    import switchPlanMixins from 'aa/vue/components/account/pages/accountSwitchPlan/variants/switchPlanMixins';
    import { updatePlanDescription } from 'aa/vue/stores/modules/MultiSubPlanStore';
    import AnnualCouponIcon from 'aa/vue/atoms/AnnualCouponIcon';

    export default {
        name: 'MultiSubBox',
        mixins: [couponsMixin, pricePrettyPrint, switchPlanMixins, commonMixins],
        data: () => {
            return {
                coupon: '',
                couponString: null,
                switchPlanTermsTagOne:
                    "<a href='https://pplus.legal/subscription' class='ui-link qt-terms form-link' target='_blank'>",
                switchPlanTermsTagTwo: '</a>',
                promoAppliedToTier: false,
                promoAppliedToCadence: false,
                pricingNextSubtotal: 0,
                pricingNowSubtotal: 0,
                // START feature/PPIWEBM-1594
                expVariant: '',
                waitingForVariant: false,
                // END feature/PPIWEBM-1594
            };
        },
        props: {
            idx: {
                type: Number,
            },
            plan: {
                type: Object,
                default: function () {
                    return {
                        plan_title: '',
                        plan_description: '',
                        plan_price: '',
                        plan_currency: '',
                        plan_period: '',
                        plan_trial: '',
                        legal_disclaimer: '',
                        plan_pill: '',
                    };
                },
            },
            isActive: {
                type: Boolean,
            },
            isCadenceStep: {
                type: Boolean,
                default: false,
            },
            isCurrent: {
                type: Boolean,
            },
            isDisabled: {
                type: Boolean,
            },
            isDowngrade: {
                type: Boolean,
            },
            displayFeaturesList: {
                type: Boolean,
                default: false,
            },
            isSwitchPlan: {
                type: Boolean,
            },
            isUpgrade: {
                type: Boolean,
            },
            sameBillingTimeframe: {
                type: Boolean,
            },
            totalPlans: {
                type: Number,
                default: 1,
            },
            pickAPlanTracking: {
                type: String,
                default: '',
            },
            stepClass: {
                type: String,
                default: 'cadence',
            },
            isPlanDescriptionEmpty: {
                type: Boolean,
                default: false,
            },
            maxPlanInfoHeight: {
                type: Number,
                default: 0,
            },
            cadenceHasDiscountedSubtotals: {
                type: Boolean,
                default: false,
            },
            isMobile: {
                type: Boolean,
                default: false,
            },
            cadencesHasAdditionalInfo: {
                type: Boolean,
                default: false,
            },
            hasPromoForMonthlyCadenceOnly: {
                type: Boolean,
                default: false,
            },
        },
        components: {
            ButtonCta,
            MultiSubBoxData,
            LearnMoreLegal,
            AnnualCouponIcon,
        },
        mounted() {
            this.$nextTick(() => {
                if (this.recurly) {
                    this.updateTrialCopyByCoupon();
                }
            });
            this.matchHeights();
            this.$emit('max-plan-info-height', this.$refs.planWrapper.clientHeight);
        },
        async created() {
            if (this.isSwitchPlan && (!this.isMobileDevice || this.isActive)) {
                this.$emit('btn-display-text', this.btnDisplayText);
            }
            // START feature/PPIWEBM-1594
            if (this.isSwitchPlan) {
                return;
            }

            const isTierAndShouldLookForExpVariant = Boolean(
                this.stepClass === 'tier' && this.plan.plan_pill && this.plan.plan_pill_variant,
            );

            const isCadenceAndShouldLookForExpVariant = Boolean(
                this.stepClass === 'cadence' &&
                    this.plan.price_plan_savings_v1 &&
                    this.plan.price_plan_savings_v2,
            );

            // START feature/PPIWEBM-2028
            const isAnnualPlanHighlightLookForExpVariant = Boolean(
                this.stepClass === 'cadence' &&
                    this.activeCadenceOptions?.annual_plan_price_savings &&
                    this.isABTestAnnualPlanHighlightFeature,
            );
            // END feature/PPIWEBM-2028

            if (
                isTierAndShouldLookForExpVariant ||
                isCadenceAndShouldLookForExpVariant ||
                isAnnualPlanHighlightLookForExpVariant
            ) {
                const expName = this.getPillExpName();
                if (expName) {
                    this.waitingForVariant = true;
                    try {
                        const res = await this.loadVariantsXhr([expName]);
                        this.expVariant = res.variants?.[expName];
                    } catch (e) {
                        console.error(e);
                        this.expVariant = '';
                    }
                    this.waitingForVariant = false;
                }
            }
            // END feature/PPIWEBM-1594
        },
        computed: {
            ...mapGetters('plan', [
                'skipStandardCadence',
                'skipBasicCadence',
                'skipPremiumCadence',
                'getLegals',
                'getCadences',
                'currentPlan',
                'getTiersWithValidPromos',
                'activeCadenceOptions',
                'validPromoExists',
            ]),
            ...mapGetters(['featureIsActive']),
            hasPromo() {
                return (
                    this.couponString &&
                    (this.$parent.$options.name === 'Cadence' || this.skipCadence)
                );
            },
            skipCadence() {
                let currentTier =
                    this.plan.plan.planTier === Product.TIER_BASIC
                        ? 'basic'
                        : this.plan.plan.planTier;
                currentTier = currentTier.charAt(0).toUpperCase() + currentTier.slice(1);
                return this[`skip${currentTier}Cadence`];
            },
            legal() {
                return this.getLegals?.find((legal) => {
                    return legal.tags.find((tag) =>
                        tag.includes(
                            `${this.isCadenceStep ? 'billing' : 'plan'}_${this.plan.plan.planTier}`,
                        ),
                    );
                });
            },
            isDowngradingOnTrial() {
                // If we don't have a currentPlan than we're in the purchase flow
                if (this.currentPlan) {
                    return this.isCadenceStep && this.isDowngrade && this.currentPlan?.on_trial;
                }
                return false;
            },
            btnDisplayText() {
                let displayText = this.plan?.plan_button ?? this.plan?.plan_submit_button;

                if (this.isCurrent) {
                    const currentDisplayText =
                        this.plan?.current_plan_submit_button ?? this.plan?.plan_button_current;
                    if (currentDisplayText) {
                        displayText =
                            currentDisplayText.trim().length > 0 ? currentDisplayText : displayText;
                    }
                }

                return displayText ?? '';
            },
            getButtonConfig: function () {
                let displayText = this.btnDisplayText;
                return {
                    styleClass: `button primary multi-sub-cta ${this.isDisabled || this.isDowngradingOnTrial ? 'disabled' : ''}`,
                    displayText,
                    tabIndex: '0',
                    disabled: this.isDisabled || this.isDowngradingOnTrial,
                    event: this.handleClick,
                };
            },
            currentTierHasPromo() {
                return this.getTiersWithValidPromos.includes(this.plan.plan.planTier);
            },
            getPillText() {
                //EU Compliance logic

                //Display 'PROMOTIONS AVAILABLE'
                const promotionsAvailableForTierOnly =
                    this.stepClass === 'tier' && this.currentTierHasPromo && !this.isCurrent;

                //Display 'PROMOS'
                const promosForCadencesOnly = this.hasPromo && this.stepClass === 'cadence';

                //No pill displayed for annual cadence
                const noPillDisplayedAnnualCadenceOnly =
                    !this.hasPromo &&
                    this.stepClass === 'cadence' &&
                    this.plan.planType === Product.TYPE_ANNUAL;

                //No pill displayed for monthly cadence
                const noPillDisplayedMonthlyCadenceOnly =
                    this.stepClass === 'cadence' &&
                    !this.hasPromo &&
                    this.plan?.planType === Product.TYPE_MONTHLY;

                if (!this.isCurrent) {
                    if (promotionsAvailableForTierOnly) {
                        const tierPromoAvailablePill =
                            this.$store.state.plan.shared?.tier_promoavailable_pill;
                        return tierPromoAvailablePill;
                    }

                    if (promosForCadencesOnly) {
                        return this.$store.state.plan.shared?.cadence_promo_pill;
                    }

                    if (noPillDisplayedAnnualCadenceOnly || noPillDisplayedMonthlyCadenceOnly) {
                        return;
                    }
                    //End EU Compliance logic

                    // START feature/PPIWEBM-1594
                    return this.getPillTextContent(this.plan.plan_pill);
                    // END feature/PPIWEBM-1594
                } else {
                    return this.plan.current_plan;
                }
                //End Non-EU Compliance logic
            },
            getAnnualSavingsText() {
                if (
                    this.stepClass !== 'cadence' ||
                    this.plan.plan.planType === Product.TYPE_MONTHLY
                ) {
                    return false;
                }

                // START feature/PPIWEBM-1594
                return this.getPillTextContent(this.activeCadenceOptions.annual_plan_price_savings);
                // END feature/PPIWEBM-1594
            },
            recurly() {
                return this.$store.state.payment.recurly;
            },
            promo() {
                return this.$store.state.promo;
            },
            isMobileDevice() {
                return this.$vuetify.breakpoint.mobile;
            },
            trackingService() {
                return this.isSwitchPlan ? this.$trackingService : tracking;
            },
            showDiscountedSubtotals() {
                if (this.promoAppliedToCadence) {
                    return false;
                }

                if (this?.coupon?.discount?.type === 'free_trial' || this.isCurrent) {
                    return false;
                }

                if (
                    (this.stepClass === 'cadence' && this.hasPromo) ||
                    (this.coupon && this.couponString)
                ) {
                    this.$emit('cadence-has-discounted-subtotals', true);
                    this.$nextTick(() => {
                        this.$emit('max-plan-info-height', this.$refs.planWrapper.clientHeight);
                    });

                    return true;
                }

                if (this.coupon && this.couponString) {
                    return true;
                }

                return false;
            },
            alignCadencePrices() {
                return (
                    !this.isMobile &&
                    !this.showDiscountedSubtotals &&
                    this.cadenceHasDiscountedSubtotals
                );
            },
            showNext() {
                if (!this.pricing) {
                    return;
                }

                const subtotal_now = parseFloat(this.pricing?.price?.now?.subtotal);
                const { rawPrice } = this.plan.plan;

                return subtotal_now === rawPrice || !subtotal_now;
            },
            ...mapGetters('user', ['isNoTrialUser']),
            displayTrialInfo() {
                if (this.coupon && this.coupon.discount.type === 'free_trial') {
                    return false;
                }
                return this.plan?.plan?.trialString;
            },
            getPromoHeadline() {
                let combinedPromoHeadline;
                const freeTrialDetails = this.displayTrialInfo;
                const promoDuration = this.couponString;

                if (!promoDuration && freeTrialDetails) combinedPromoHeadline = freeTrialDetails;
                else if (promoDuration && !freeTrialDetails) combinedPromoHeadline = promoDuration;
                else if (freeTrialDetails && promoDuration)
                    combinedPromoHeadline = `${promoDuration}, ${freeTrialDetails}`;

                return combinedPromoHeadline;
            },
            showPromoDetails() {
                return this.hasPromo && this.stepClass === 'cadence' && !this.isCurrent;
            },
            isEnhancedPaymentSummaryEnabled() {
                return this.$store.state.serverData.enhancedPaymentSummaryEnabled;
            },
            hasActiveCouponAndOnTrial() {
                if (
                    this.isEnhancedPaymentSummaryEnabled &&
                    this.hasActiveCoupon &&
                    this.currentPlan?.on_trial &&
                    this.stepClass === 'cadence'
                )
                    return true;
                else return false;
            },
            showAdditionalInfo() {
                const showAdditionalInfo = this.displayFeaturesList || this.showPromoDetails;
                this.$emit('cadences-has-additional-info', showAdditionalInfo);

                return showAdditionalInfo;
            },
            showDividerLine() {
                return !this.isMobile && this.cadencesHasAdditionalInfo && !this.showAdditionalInfo;
            },
            formattedSubtotalNext() {
                if (!this.pricing) {
                    return;
                }

                const { currencySymbol, currencyCode } = this.pricing;

                return this.pricePrettyPrint(
                    this.pricingNextSubtotal,
                    currencySymbol,
                    currencyCode,
                    CBS.Registry.region.locale,
                    false,
                );
            },
            formattedSubtotalNow() {
                if (!this.pricing) {
                    return;
                }

                const { currencySymbol, currencyCode } = this.pricing;

                return this.pricePrettyPrint(
                    this.pricingNowSubtotal,
                    currencySymbol,
                    currencyCode,
                    CBS.Registry.region.locale,
                    false,
                );
            },
            // START feature/PPIWEBM-2028
            isABTestAnnualPlanHighlightFeature() {
                return this.featureIsActive(AB_TEST_ANNUAL_PLAN_HIGHLIGHT);
            },
            isAnnualPlanHighlightGreenPill() {
                return (
                    this.isABTestAnnualPlanHighlightFeature &&
                    this.expVariant === AB_TEST_ANNUAL_PLAN_HIGHLIGHT_GREEN_PILL
                );
            },
            isAnnualPlanHighlightIconAndGreenPill() {
                return (
                    this.isABTestAnnualPlanHighlightFeature &&
                    this.expVariant === AB_TEST_ANNUAL_PLAN_HIGHLIGHT_ICON_AND_GREEN_PILL
                );
            },
            isAnnualPlanHighlightAll() {
                return (
                    this.isABTestAnnualPlanHighlightFeature &&
                    this.expVariant === AB_TEST_ANNUAL_PLAN_HIGHLIGHT_ALL
                );
            },
            isAnnualPlanHighlightShowDiscountIcon() {
                return this.isAnnualPlanHighlightIconAndGreenPill || this.isAnnualPlanHighlightAll;
            },
            getAnnualPlanHighlightClasses() {
                let classes = ['plan-box__pill'];

                if (
                    this.isAnnualPlanHighlightGreenPill ||
                    this.isAnnualPlanHighlightIconAndGreenPill ||
                    this.isAnnualPlanHighlightAll
                ) {
                    classes.push('green-pill');
                } else {
                    //No AB Test variants, just the regular behavior:
                    classes.push('plan-box__annual-savings-pill');
                }

                return classes.join(' ');
            },
            // END feature/PPIWEBM-2028
        },
        methods: {
            // START feature/PPIWEBM-1594
            ...mapActions('variant', {
                loadVariantsXhr: 'loadVariantsXhr',
            }),
            getPillExpName() {
                if (this.featureIsActive(AB_TEST_BEST_PLAN_RECOMMENDED)) {
                    return AB_TEST_BEST_PLAN_RECOMMENDED.replace('exp_', '');
                }

                if (this.featureIsActive(AB_TEST_BEST_PLAN_RECOMMENDED_WITH_SAVE_STRING)) {
                    return AB_TEST_BEST_PLAN_RECOMMENDED_WITH_SAVE_STRING.replace('exp_', '');
                }

                // START feature/PPIWEBM-2028
                if (this.isABTestAnnualPlanHighlightFeature) {
                    return AB_TEST_ANNUAL_PLAN_HIGHLIGHT;
                }
                // END feature/PPIWEBM-2028

                return '';
            },
            getPillVariants() {
                const expName = this.getPillExpName();
                const applyAnnualPlanHighlightForCadence =
                    this.plan.planType === Product.TYPE_ANNUAL &&
                    this.isABTestAnnualPlanHighlightFeature &&
                    this.stepClass === 'cadence';
                if (AB_TEST_BEST_PLAN_RECOMMENDED.includes(expName)) {
                    return {
                        control: this.plan.plan_pill,
                        pill_1: this.plan.plan_pill_variant,
                    };
                }

                if (AB_TEST_BEST_PLAN_RECOMMENDED_WITH_SAVE_STRING.includes(expName)) {
                    return {
                        control: this.activeCadenceOptions.annual_plan_price_savings,
                        pill_1: this.plan.price_plan_savings_v1,
                        pill_2: this.plan.price_plan_savings_v2,
                    };
                }

                // START feature/PPIWEBM-2028
                if (applyAnnualPlanHighlightForCadence) {
                    switch (this.expVariant) {
                        case AB_TEST_ANNUAL_PLAN_HIGHLIGHT_ALL:
                            return {
                                control: this.plan.plan_pill,
                                emphasis_icon_and_green_pill: this.plan.plan_pill_variant,
                            };

                        case AB_TEST_ANNUAL_PLAN_HIGHLIGHT_ICON_AND_GREEN_PILL:
                            return {
                                control: this.plan.plan_pill,
                                icon_and_green_pill: this.plan.plan_pill_variant,
                            };

                        case AB_TEST_ANNUAL_PLAN_HIGHLIGHT_GREEN_PILL:
                            return {
                                control: this.plan.plan_pill,
                                green_pill: this.plan.plan_pill_variant,
                            };
                    }
                }
                // END feature/PPIWEBM-2028

                return '';
            },
            // START feature/PPIWEBM-1594
            getPillTextContent(fallback) {
                if (this.waitingForVariant) {
                    return '';
                }
                if (this.expVariant) {
                    const allVariants = this.getPillVariants();
                    return allVariants?.[this.expVariant] ?? fallback;
                }
                return fallback;
            },
            // END feature/PPIWEBM-1594
            setPlanTypeFromMouseOver: function (e) {
                e.stopPropagation();
                if (!this.isMobileDevice) {
                    this.$emit('planSelected', {
                        idx: this.idx,
                        submit: false,
                    });
                }
            },
            handleClick: function (e) {
                if (this.isDisabled) return;
                if (this.isDowngradingOnTrial) {
                    this.$emit(
                        'notify',
                        'this_action_is_not_available_during_the_free_trial_period',
                    );
                    return;
                }
                e.stopPropagation();

                if (this.isSwitchPlan) {
                    this.handleActiveCouponAndTrialNotification();
                }
                this.$emit('planSelected', {
                    idx: this.idx,
                    submit: true,
                    displayText: this.getButtonConfig.displayText,
                });
                this.pickPlanBtnTracking(e);
            },

            pickPlanBtnTracking(e) {
                const planSelected = this.plan.plan;
                const button = e.target.closest('.plan-box.-multi-sub').querySelector('button');
                const trackingParams = {
                    ctaText: button.textContent.trim(),
                    pickPlanType: planSelected.planTier,
                };
                let trackActionName;
                if (this.pickAPlanTracking === 'tier') {
                    if (this.isSwitchPlan) {
                        trackActionName = 'trackSwitchPlan';
                        trackingParams.productCurrentSku = this.currentPlan.product_code;
                        trackingParams.pageType = 'svod_switch-plan';
                        trackingParams.switchPlanClick = 1;
                    } else {
                        trackActionName = 'trackPickPlanSelection';
                        trackingParams.pickPlanSelection = 1;
                    }
                    this.trackingService.trackAction(trackActionName, trackingParams);
                } else if (
                    this.pickAPlanTracking === 'cadence' &&
                    !this.$route.params?.isCrossgrade
                ) {
                    trackActionName = 'trackBillingCycleSelection';
                    trackingParams.productPricingPlan = planSelected.planType;
                    if (this.isSwitchPlan) {
                        trackingParams.productCurrentSku = this.currentPlan.product_code;
                        trackingParams.productNewSku = planSelected.recurlyCode;
                    } else {
                        trackingParams.pickPlanSku = planSelected.recurlyCode;
                    }
                    this.trackingService.trackAction(trackActionName, trackingParams);
                }
            },
            handleCardClick: function (e) {
                if (this.isDisabled) return;
                if (this.isDowngradingOnTrial) {
                    this.$emit(
                        'notify',
                        'this_action_is_not_available_during_the_free_trial_period',
                    );
                    return;
                }
                e.stopPropagation();
                if (this.isSwitchPlan) {
                    this.$emit('btn-display-text', this.btnDisplayText);
                    this.handleActiveCouponAndTrialNotification();
                }
                this.$emit('planSelected', {
                    idx: this.idx,
                    // Need confirmation on this
                    // submit: !this.isMobileDevice && !this.isSwitchPlan,
                    submit: !this.isMobileDevice,
                    displayText: this.getButtonConfig.displayText,
                });
                if (!this.isSwitchPlan && !this.isMobileDevice) {
                    this.pickPlanBtnTracking(e);
                }
            },
            handleActiveCouponAndTrialNotification() {
                if (this.hasActiveCouponAndOnTrial) {
                    let message =
                        this.$store.state.serverData.activePromoMayNotApplyToNewPlanCopy ??
                        this.getLocale(
                            'if_you_currently_have_an_active_promotion_it_may_not_apply_to_your_new_plan',
                        );
                    this.openNotification(
                        new NotificationBarModel({
                            success: true,
                            message,
                            autoClose: false,
                        }),
                    );
                }
            },
            matchHeights() {
                const root = document.documentElement;

                if (
                    this.$refs?.title?.clientHeight >
                    root.style.getPropertyValue('--plan-box__title-height')
                ) {
                    root.style.setProperty(
                        '--plan-box__title-height',
                        this.$refs.title.clientHeight,
                    );
                }

                if (
                    this.$refs?.copy?.clientHeight >
                    root.style.getPropertyValue('--plan-box__copy-height')
                ) {
                    root.style.setProperty('--plan-box__copy-height', this.$refs.copy.clientHeight);
                }

                if (
                    this.$refs?.price?.clientHeight >
                    root.style.getPropertyValue('--plan-box__price-height')
                ) {
                    root.style.setProperty(
                        '--plan-box__price-height',
                        this.$refs.price.clientHeight,
                    );
                }
            },
            onCouponSuccess(coupon) {
                if (this.pricing) {
                    const { currencySymbol, currencyCode } = this.pricing;
                    let couponString = this.formatCouponString(
                        coupon,
                        currencySymbol,
                        currencyCode,
                        this.plan.plan.planType,
                    );

                    const updateCouponString = (recurlyCode) => {
                        this.$store.dispatch('plan/updatePlanCouponString', {
                            recurlyCode,
                            planTier: this.plan.plan.planTier,
                            couponString,
                        });
                    };

                    this.$nextTick(() => {
                        this.pricingNextSubtotal = this.pricing.price.next.subtotal;
                        this.pricingNowSubtotal = this.pricing.price.now.subtotal;

                        if (
                            this.plan.original_plan_description &&
                            this.plan.planType === Product.TYPE_ANNUAL
                        ) {
                            this.plan.plan_description = updatePlanDescription(
                                this.plan.original_plan_description,
                                this.plan.plan,
                                this.pricingNextSubtotal,
                            );
                        }
                    });

                    if (
                        coupon?.applies_to_all_plans === true ||
                        coupon?.plans?.includes(this.plan?.plan?.recurlyCode)
                    ) {
                        this.couponString = couponString;

                        // coupon.plans is empty when the coupon applies to all plans
                        updateCouponString(this.plan?.plan?.recurlyCode);
                    } else {
                        // Only update the coupon string for plans that the coupon applies to.
                        // This prevents the case where annual coupons run through here but we are
                        // currently display the monthly plan on the tier page
                        coupon?.plans.forEach(updateCouponString);
                    }

                    this.$emit('max-plan-info-height', this.$refs.planWrapper.clientHeight);
                }
            },
            onCouponFailure(error) {
                // ignore
            },
            updateTrialCopyByCoupon() {
                if (typeof this.recurly === 'object') {
                    try {
                        this.recurly.configure(CreditCardPaymentConfig.recurlyConfig);
                        let { promo } = this.$store.state;
                        //The store doesn't have the promo during switch plan, so retrieve it from the route
                        if (!promo && this.$route.query.promo) {
                            promo = this.$route.query.promo;
                            this.$store.commit('setPromo', promo);
                        }

                        if (promo) {
                            this.obtainPricesFromRecurly();
                            // from coupons Mixin
                            this.setupRecurlyPricing(
                                this.onCouponSuccess,
                                this.onCouponFailure,
                                false,
                            );
                        }
                    } catch (e) {
                        // ignore
                    }
                }
            },
            updateLegalText(disclaimer) {
                return disclaimer
                    .replace(/<Full Price>/gi, this.plan.plan_price)
                    .replace(/<term link>/i, this.switchPlanTermsTagOne)
                    .replace(/<term link>/i, this.switchPlanTermsTagTwo);
            },
            applyCouponToPlans(event) {
                let { recurlyInfo } = this.coupon;
                if (!recurlyInfo || recurlyInfo.code !== event.couponCode) {
                    this.submittedCouponCode = event.couponCode;
                    this.pricing
                        .plan(event.plan)
                        .coupon(event.couponCode)
                        .catch((e) => {
                            this.promoAppliedToTier = false;
                            if (!this.promoAppliedToTier) {
                                this.applyPlanTiersPromoFromCadences();
                            }
                        })
                        .done(() => {
                            this.promoAppliedToTier = true;
                        });
                }
            },
            obtainPricesFromRecurly() {
                if (this.recurly) {
                    this.pricing = this.recurly.Pricing();

                    if (!this.isEditPayment) {
                        let _self = this;
                    }
                    // Apply coupon
                    let { promo } = this.$store.state;

                    if (promo) {
                        this.applyCouponToPlans({
                            plan: this.plan.plan.recurlyCode,
                            couponCode: promo,
                        });
                    }
                }
            },
            planRenewalText() {
                const monthlyRenewalText = this.$store.state.plan.shared?.autorenewal_terms_monthly;
                const annualRenewalText = this.$store.state.plan.shared?.autorenewal_terms_annual;
                const renewalText =
                    this.plan.plan.planType === Product.TYPE_MONTHLY
                        ? monthlyRenewalText
                        : annualRenewalText;

                return renewalText;
            },
            //Know ahead of time in the tier page if annual-only promotions in the cadence page are applicable to tier plans
            applyPlanTiersPromoFromCadences() {
                if (!this.isCurrent && this.stepClass === 'tier' && this.pricing) {
                    this.processFilteredCadences();
                }
            },
            processFilteredCadences() {
                const filteredCadences = this.getCadences.filter(
                    (cadence) => cadence?.planTier === this?.plan?.planTier,
                );

                if (filteredCadences) {
                    filteredCadences.forEach((filtered) => {
                        filtered.plans?.forEach((cadence) => {
                            if (cadence?.plan?.recurlyCode) {
                                this.pricing
                                    .plan(cadence?.plan?.recurlyCode)
                                    .coupon(this.promo)
                                    .catch((e) => {})
                                    .done(() => {
                                        this.promoAppliedToCadence = true;
                                    });
                            }
                        });
                    });
                }
            },
        },
        watch: {
            recurly(newVal, oldVal) {
                if (newVal) {
                    this.updateTrialCopyByCoupon();
                }
            },
            promo(newVal, oldVal) {
                if (newVal) {
                    this.updateTrialCopyByCoupon();
                }
            },
        },
    };
</script>
